<template>
  <!-- <div>
    <q-btn @click="_next_token_id">
      Get Next Token ID
    </q-btn>
    {{nft}}
  </div> -->

  <div
    v-if="nft"
    class="my-page fit row wrap justify-center items-start content-start"
  >
    <div class="col-xs-12 col-md-6 q-pa-md">
      <!-- <a target="_blank" :href="nft.data.link"> -->
      <div class="frame" @click="show_image(nft)">
        <div class="mat">
          <div class="art">
            <q-img :ratio="1" :src="nft.data.image">
              <!-- <a @click.stop="opensea(nft)">
                <q-icon
                  class="articon absolute "
                  name="img:https://opensea.io/static/images/logos/opensea.svg" 
                  size="48px"
                  color="white"
                  style="left: 16px">
                </q-icon>
              </a> -->

              <a v-if="nft.data.link" @click.stop="goto(nft.data.link)">
                <q-icon
                  class="articon absolute all-pointer-events"
                  name="link"
                  size="48px"
                  color="white"
                  style="right: 16px"
                >
                </q-icon>
              </a>
              <a v-if="nft.data.video" @click.stop="goto(nft.data.video)">
                <q-icon
                  class="articon absolute all-pointer-events"
                  name="videocam"
                  size="48px"
                  color="white"
                  style="right: 68px"
                >
                </q-icon>
              </a>
              <a v-if="nft.data.audio" @click.stop="goto(nft.data.audio)">
                <q-icon
                  class="articon absolute all-pointer-events"
                  style="right: 120px"
                  size="48px"
                  color="white"
                  name="audiotrack"
                >
                </q-icon>
              </a>
            </q-img>
          </div>
        </div>
      </div>
    </div>

    <q-card flat class="col-xs-12 col-md-6">
      <q-card-section class="text-body2 q-ml-md q-mt-md">
        {{ $t("sold_by") }}:
        <a class="text-body2 q-mr-md" :href="'/shop/' + nft.uowner.id">
          {{ nft.uowner.nickname }}
        </a>
        <p class="text-body2">
          {{ nft.uowner.email }}
        </p>
        <!-- <q-avatar size="lg">
          <img :src="nft.uowner.avatar" />
        </q-avatar> -->
      </q-card-section>
      <q-card-section class="q-ml-md">
        <!-- <q-item-label class="absolute-top-right bg-transparent q-mr-lg"> -->

        <!-- </q-item-label> -->
        <p class="text-h5 text-bold">
          {{ nft.data.name }}
          <q-icon
            class="q-ml-lg q-ma-xs"
            color="red"
            size="24px"
            name="favorite"
          />
          <span class="text-grey">{{ nft.likes }}</span>
        </p>

        <p
          v-if="Number(nft.data.price) != 0"
          class="text-h5 text-blue text-bold"
        >
          ${{ nft.data.price }}
        </p>
        <p v-else class="text-accent">{{ $t("free") }}</p>

        <p class="text-body2">
          {{ $t("create_date") }}: {{ pdate(nft.created_at) }}
        </p>
        <p class="text-body2">
          {{ $t("total") }}: {{ nft.total }} | {{ $t("remains") }}:
          {{ nft.total - nft.token_sum > 0 ? nft.total - nft.token_sum : 0 }}
        </p>
        <p class="text-body2">
          {{ $t("contract") }}:
          <a :href="chainlnk">{{ nft.id.substring(0, 18) + "..." }}</a>
        </p>

        <p class="text-subtitle2">{{ $t("author") }}: {{ nft.data.author }}</p>
        <p class="text-subtitle2">{{ $t("about") }}</p>
        <p class="text-body">
          {{ nft.data.description }}
        </p>

        <!-- <p class="text-body2" v-if="nft.data.direct_info">
          Payment info: {{ nft.data.direct_info }}
        </p> -->
        <div v-if="nft.total - nft.token_sum <= 0">
          <q-btn
            class="full-width q-ma-xs"
            color="grey"
            :label="$t('sold_out')"
            size="20px"
            disable
          />
          <q-btn
            class="full-width q-ma-xs"
            color="orange"
            :label="$t('share')"
            size="20px"
            @click="share()"
          />
        </div>

        <div v-else>
          <div v-if="!user">
            <q-btn
              v-if="Number(nft.data.price) == 0"
              class="full-width q-ma-xs"
              color="blue"
              :label="$t('free_claim')"
              size="20px"
              @click="login"
            />
            <q-btn
              v-else
              class="full-width q-ma-xs"
              color="blue"
              :label="$t('buy_now')"
              size="20px"
              @click="login"
            />
            <q-btn
              class="full-width q-ma-xs"
              color="orange"
              :label="$t('share')"
              size="20px"
              @click="login"
            />
          </div>

          <div v-else>
            <div v-if="Number(nft.data.price) == 0">
              <q-btn
                :loading="claiming"
                class="full-width q-ma-xs"
                color="blue"
                :label="$t('free_claim')"
                size="20px"
                @click="claim_confirm = true"
              >
                <template v-slot:loading>
                  <q-spinner-hourglass class="on-left" />
                  Loading
                </template>
              </q-btn>
            </div>
            <div v-else>
              <p class="full-width q-ma-md" v-if="nft.data.is_buy_directly">
                {{ $t("buy_direct") }} : {{ nft.data.buy_direct_info }}
              </p>
              <q-btn
                v-if="nft.data.is_credit_card"
                :loading="claiming"
                class="full-width q-ma-xs"
                color="orange"
                :label="$t('buy_now')"
                size="20px"
                @click="order_confirm = true"
              >
                <template v-slot:loading>
                  <q-spinner-hourglass class="on-left" />
                  Loading
                </template>
              </q-btn>
            </div>

            <q-btn
              class="full-width q-ma-xs"
              color="orange"
              :label="$t('share')"
              size="20px"
              @click="share()"
            />
          </div>
        </div>
        <input hidden type="text" :value="share_url" id="my_share_url" />
      </q-card-section>
    </q-card>
  </div>
  <!-- NFT List -->

  <p class="my-more text-h6 q-pa-md">{{ $t("more_from_seller") }}</p>

  <div class="q-pa-md row my-list">
    <div
      v-for="k in morenfts"
      :key="k.id"
      class="q-pa-md col-xs-12 col-sm-6 col-md-4 col-lg-3"
    >
      <q-card class="my-card" @click="this.$router.push('/nft/' + k.id)">
        <q-img :ratio="1" class="q-pa-md" :src="k.data.image">
          <q-img
            v-if="k.total - k.token_sum <= 0"
            class="absolute-center"
            src="@/assets/soldout.png"
          />
        </q-img>

        <q-card-section>
          <div class="text-h6">{{ k.data.name }}</div>
          <!-- <a
            class="my-link text-body"
            :href="'https://mumbai.polygonscan.com/address/' + k.id"
          >
            {{k.id}}
          </a> -->

          <div class="text-body2">
            {{ pdate(k.created_at) }}
          </div>
          <div class="text-body2">{{ $t("price") }}: ${{ k.data.price }}</div>
          <p class="text-body2">
            {{ $t("total") }}: {{ k.total }} | {{ $t("remains") }}:
            {{ k.total - k.token_sum > 0 ? k.total - k.token_sum : 0 }}
          </p>
        </q-card-section>
        <q-item-label class="absolute-bottom-right bg-transparent q-ma-sm">
          <q-icon class="q-ma-sm" color="red" size="24px" name="favorite" />
          <span class="text-weight-bold">{{ k.likes }}</span>
        </q-item-label>
        <!-- <q-card-section class="q-pt-none">
          <q-btn
            class="my-card-btn"
            color="blue"
            :label="$t('view')"
            :to="'/nft/' + k.id"
          />
        </q-card-section> -->
      </q-card>
    </div>
  </div>

  <!-- transactions list -->
  <p class="my-more text-h6 q-pa-md">{{ $t("recent_txn") }}</p>
  <div class="q-ma-lg q-pa-lg">
    <q-table
      :rows="recent_txns"
      :columns="columns"
      row-key="ID"
      :rows-per-page-options="[0]"
    />
  </div>
  <q-dialog seamless full-width position="bottom" v-model="book_confirm">
    <q-card>
      <q-card-section class="q-mt-lg q-pt-none text-blue text-center">
        <p class="text-h6 text-orange">
          You Booked 1 NFT, will mint to your wallet soon!
        </p>
        <p class="text-body">{{ nft.id }}</p>
        <p class="text-body2"># {{ tokenid }}</p>
      </q-card-section>

      <q-card-actions align="center">
        <q-btn color="primary" v-close-popup>OK</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog seamless full-width position="bottom" v-model="confirm">
    <q-card>
      <q-card-section class="q-mt-lg q-pt-none text-blue text-center">
        <p class="text-h6 text-orange">{{ $t("claimed_nft") }}</p>
        <p class="text-body">{{ nft.id }}</p>
        <p class="text-body2"># {{ tokenid }}</p>
        <p class="text-h6">
          <a
            class="text-body2"
            :href="chainscan_url + 'tx/' + receipt.transactionHash"
            >View Transaction</a
          >
        </p>
      </q-card-section>

      <q-card-actions align="center">
        <q-btn color="primary" v-close-popup>OK</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog seamless full-width position="bottom" v-model="alert">
    <q-card>
      <q-card-section>
        <div class="text-h6">Alert</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        {{ $t(alert_msg) }}
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat size="20px" label="OK" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog class="q-ma-md" v-model="order_confirm" persistent>
    <q-card class="bg-teal text-white my_order_card items-center">
      <q-card-section class="row items-center">
        <q-avatar icon="shopping_cart" color="primary" text-color="white" />
        <span class="q-ma-md">
          {{ $t("you_are_buying") }} 1 NFT {{ nft.data.name }}.
        </span>
      </q-card-section>
      <div class="q-ma-md row justify-center text-center q-gutter-md">
      <!-- <q-card class="my-card q-ma-md" @click="buynow(nft)">
        <q-img src="@/assets/creditcard.png">
          <div class="text-h5 absolute-bottom text-center">
            {{ $t("make_payment") }}
          </div>
        </q-img>
        
      </q-card> -->

      <q-card class="my-card q-ma-md" @click="create_charge(nft)">
        <q-img src="@/assets/coinbase.png">
          <div class="text-h5 absolute-bottom text-center">
            {{ $t("coinbase") }}
          </div>
        </q-img>
      </q-card>
      </div>
      <q-card-actions align="right">
        <q-btn flat label="Cancel"    color="white"

              size="20px" v-close-popup />
      </q-card-actions>
    </q-card>
    <!-- <q-card>
      
      <q-card-section class="row items-center">
        <q-avatar icon="shopping_cart" color="primary" text-color="white" />
        <span class="q-ml-sm">
          {{ $t("you_are_buying") }} 1 NFT {{ nft.data.name }}.
        </span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn 
          flat 
          label="Cancel" 
          color="grey" 
          v-close-popup
        />
        <q-btn
          flat
          :label="$t('make_payment')"
          color="blue"
          v-close-popup
          @click="buynow(nft)"
        />
        <q-btn
          flat
          :label="$t('coinbase')"
          color="blue"
          v-close-popup
          @click="create_charge(nft)"
        />
      </q-card-actions>
    </q-card> -->
  </q-dialog>

  <q-dialog v-model="claim_confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="shopping_cart" color="primary" text-color="white" />
        <span class="q-ml-sm">
          {{ $t("free_claim") + " " + nft.data.name }}
        </span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn 
          size="lg"
          flat 
          :label="$t('cancel')" 
          color="primary" 
          v-close-popup 
        />
        <q-btn
          flat
          :label="$t('free_claim')"
          color="orange"
          v-close-popup
          @click="claim"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="dialog" persistent class="mydialog">
    <q-card class="mycard">
      <div class="q-mp-lg absolute-top-right">
        <q-icon
          class="myclose"
          size="48px"
          name="close"
          color="white"
          @click="dialog = false"
        />
      </div>
      <q-card-section class="q-ma-lg">
        <a :href="cur_token_image" download="token-image">
          <div class="frame">
            <div class="mat">
              <div class="art">
                <q-img :ratio="1" fit="contain" :src="cur_token_image" />
              </div>
            </div>
          </div>
        </a>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<style lang="sass" scoped>
.my-page
  padding-left: 1rem
  padding-right: 1rem

.frame
  position: relative
  width: 100%
  padding-bottom: 100%
  background: black
  box-shadow: 0 10px 7px -5px rgba(0, 0, 0, 0.3)
  cursor: pointer

.mat
  position: absolute
  background: white
  top: 2.5%
  bottom: 2.5%
  left: 2.5%
  right: 2.5%
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5) inset

.art
  position: absolute
  top: 5%
  // bottom: 5%
  left: 5%
  right: 5%

.art::after
  content: ''
  display: block
  position: absolute
  top: 0
  width: 100%
  height: 100%
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5) inset
.articon
  bottom: 8px
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,1)
  text-shadow: 0 0 10px #000000
  z-index: 100

.my-list
  padding-left: 1rem
  padding-right: 1rem
  .my-card
    cursor: pointer
    .my-card-btn
      width: 100%

.my-more
  margin-top: 2rem
  margin-left: 2rem
  margin-bottom: 0
  padding-bottom: 0
  padding-left: 3rem
  padding-right: 3rem

.myclose
  cursor: pointer
.my-card
  width: 100%
  max-width: 300px
  cursor: pointer

.mycard
  width: 1024px
  max-width: 90vw
  background-color: rgba(255,255,255,0)
  box-shadow: 0 0 0 0 rgba(255,255,255,0)
.my_order_card
  min-width: 50%
</style>

<script>
import { useRoute } from "vue-router";
import { ref, onBeforeMount } from "vue";
import { mapState, useStore } from "vuex";
import axios from "axios";
import { supabase as s } from "../supabase";
import router from "@/router";
import { useQuasar } from "quasar";
import {
  chainscan,
  APIURL,
  next_tid,
  next_token_id,
  likeit,
  share_to,
} from "../web3.js";
import { date } from "quasar";

export default {
  name: "NFT",
  setup() {
    console.log("NFT setup");
    const id = useRoute().params.id;
    const nft = ref();
    const morenfts = ref();
    const recent_txns = ref();
    const chainscan_url = ref(chainscan);
    const user = ref(s.auth.user());
    const share_url = ref("");
    onBeforeMount(async () => {
      // set refer to local storage, send to server when user signin
      {
        // set refer to local storage, send to server when user signin
        // if local has already has a referer or currently exist login user don't need set referer
        // only set referer for the clean new user first visit
        if (!localStorage.getItem("referer") && !user.value) {
          // query refer user info
          if (useRoute().query.rid == undefined) {
            console.log("No referer id, not a referer page");
          } else {
            const rid = useRoute().query.rid;
            console.log("referer uid", rid);
            let { data: u, error } = await s
              .from("users")
              .select("*")
              .eq("id", rid);
            if (error) console.log(error);
            else {
              console.log("Query refer user:", u[0]);
              localStorage.setItem("referer", u[0].id);
              console.log("set local referer id:", u[0].id);
            }
          }
        }
      }

      let { data: nfts, error } = await s
        .from("v_nfts")
        .select("*,uowner:uowner(*)")
        .eq("id", id);
      if (error) console.log(error);
      nft.value = nfts[0];
      console.log(nft.value);

      let { data: more, error2 } = await s
        .from("v_nfts")
        .select("*,uowner:uowner(*)")
        .eq("uowner", nft.value.uowner.id)
        .eq("listing", true)
        .gt("flag", -1000)
        .neq("id", nft.value.id)
        .limit(12)
        .order("created_at", { ascending: false });
      morenfts.value = more;

      let { data: txns } = await s
        .from("txns")
        .select("*")
        .eq("contract", nft.value.id)
        .limit(30)
        .order("created_at", { ascending: false });
      console.log("recent txns:", txns);
      recent_txns.value = txns;

      likeit(nft.value.id);
      console.log("userid:", user.value.id);
      if (user.value) {
        share_url.value =
          "https://cnft.us/nft/" + nft.value.id + "?rid=" + user.value.id;
      } else {
        share_url.value = "https://cnft.us/nft/" + nft.value.id;
      }
    });

    const chainlnk = chainscan_url.value + "address/" + id;
    const q = useQuasar();
    return {
      dialog: ref(false),
      chainscan_url,
      tokenid: ref(0),
      q,
      confirm: ref(false),
      alert: ref(false),
      receipt: ref({}),
      alert_msg: ref(""),
      claiming: ref(false),
      nft,
      morenfts,
      chainlnk,
      user,
      order_confirm: ref(false),
      claim_confirm: ref(false),
      user_buy_method: ref(""),
      cur_token_image: ref(""),
      share_url,
      share_to,
      book_confirm: ref(false),
      recent_txns,
      columns: [
        {
          name: "id",
          required: true,
          label: "ID",
          field: "id",
        },
        {
          name: "type",
          label: "TYPE",
          field: "type",
        },
        {
          name: "tokenid",
          label: "TOKEN ID",
          field: "tid",
        },
        {
          name: "to",
          required: true,
          label: "TO",
          field: "to",
        },
        {
          name: "status",
          label: "STATUS",
          field: "status",
        },
        {
          name: "created_at",
          label: "DATE",
          field: "created_at",
          format: (val) => date.formatDate(val, "YYYY-MM-DD HH:mm:ss"),
        },
      ],
    };
  },

  methods: {
    share() {
      share_to(this.nft.data.name, this.nft.data.description, this.share_url);
    },
    // opensea(nft) {
    //   window.open("https://opensea.io/assets/matic/" + nft.contract);
    // },
    goto(u) {
      window.open(u);
    },
    show_image(k) {
      console.log("Show image");
      this.dialog = true;
      this.cur_token_image = k.data.image;
    },
    login() {
      console.log("call login");
      console.log("src:", window.location.pathname);
      this.$router.push({
        path: "/login",
        query: {
          src: window.location.pathname,
        },
      });
    },
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },

    async buy_directly() {
      const to_addr = this.user.user_metadata.wallet;
      const tid = next_tid();
      // Insert TXN record
      const record = {
        ufrom: this.nft.uowner.id,
        uto: this.user.id,
        contract: this.nft.id,
        tid: tid,
        from: "NULL",
        to: to_addr,
        price: this.nft.data.price,
        type: "BUY_DIRECT",
        status: "PENDING",
      };
      const { data, error } = await s.from("txns").insert([record]);

      if (error) {
        console.log(error);
      } else {
        console.log(data);
        window.open(this.nft.data.direct_info, "_blank");
      }
    },

    async claim() {
      this.claiming = true;
      const tokenid = await next_token_id(this.nft.id);
      this.tokenid = tokenid;

      const tid = next_tid();
      const url = APIURL + "/i/claim";
      const addr = this.user.user_metadata.wallet;
      const params = {
        to: addr,
        tid: tid,
        tokenid: tokenid,
        contract: this.nft.id,
        amount: 1,
        uuid: this.user.id,
      };

      const options = {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: params,
        url,
      };
      console.log(options);
      axios(options)
        .then(async (res) => {
          console.log(res);
          this.receipt = res.data;

          let status = null;
          let txn = null;
          if (res.data.blockHash != undefined) {
            status = "DONE";
            txn = res;
            this.confirm = true;
          } else if (res.data == "BOOKED") {
            status = "BOOKED";
            txn = null;
            this.book_confirm = true;
          } else {
            console.log("Unknown response!");
            this.alert = true;
            this.alert_msg = "Unknown response!";
          }

          // insert token
          const { data: token, error } = await s.from("tokens").insert([
            {
              uowner: this.user.id,
              owner: this.user.user_metadata.wallet,
              data: this.nft.data,
              tokenid: tokenid,
              tid: tid,
              contract: this.nft.id,
            },
          ]);
          if (error) {
            console.log(error);
            this.alert = true;
            this.alert_msg = "Create token failed!";
          } else {
            console.log("token record:", token[0]);
          }

          // Insert TXN record
          {
            const record = {
              ufrom: this.nft.uowner.id,
              uto: this.user.id,
              contract: this.nft.id,
              tokenid: tokenid,
              token: token[0].id,
              tid: tid,
              from: "NULL",
              to: addr,
              type: "CLAIM",
              price: this.nft.data.price,
              status: status,
              txn: txn,
            };
            const { data, error } = await s.from("txns").insert([record]);
            if (error) {
              console.log(error);
              this.alert = true;
              this.alert_msg = "Create transaction failed!";
            } else {
              console.log(data);
            }
          }
        })
        .then(() => {
          this.claiming = false;
        })
        .catch((err) => {
          this.claiming = false;
          this.alert = true;
          console.log(err.response);
          if (err.response.status == 500) {
            this.alert_msg = "Polygon Chain Network is busy, please try later!";
          } else {
            this.alert_msg = err.response.data;
          }
        });
    },
    async buynow(nft) {
      const to_addr = this.user.user_metadata.wallet;
      const tid = next_tid();
      // Insert TXN record
      const record = {
        ufrom: this.nft.uowner.id,
        uto: this.user.id,
        contract: this.nft.id,
        tid: tid,
        from: "NULL",
        to: to_addr,
        price: this.nft.data.price,
        type: "CREDIT_CARD",
        status: "PENDING",
      };
      const { data, error } = await s.from("txns").insert([record]);

      if (error) {
        console.log(error);
        return;
      } else {
        console.log(data[0]);
        this.$router.push("/checkout/" + data[0].id);
      }
    },
    async create_charge(nft) {
      const options = {
        method: "POST",
        url: "https://api.commerce.coinbase.com/charges",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CC-Api-Key": "167cf90a-b0bc-4993-86a7-daadfc18c4b7",
        },
        data: {
          local_price: {
            0: "P",
            1: "r",
            2: "i",
            3: "c",
            4: "e",
            5: " ",
            6: "i",
            7: "n",
            8: " ",
            9: "l",
            10: "o",
            11: "c",
            12: "a",
            13: "l",
            14: " ",
            15: "f",
            16: "i",
            17: "a",
            18: "t",
            19: " ",
            20: "c",
            21: "u",
            22: "r",
            23: "r",
            24: "e",
            25: "n",
            26: "c",
            27: "y",
            amount: this.nft.data.price,
            currency: "USD",
          },
          metadata: this.nft.data.author,
          name: this.nft.data.name,
          description: this.nft.data.description.substring(0,150),
          pricing_type: "fixed_price",
          redirect_url: "https://cnft.us",
          cancel_url: "https://cnft.us",
        },
      };

      const to_addr = this.user.user_metadata.wallet;
      const tid = next_tid();
      const record = {
        ufrom: this.nft.uowner.id,
        uto: this.user.id,
        contract: this.nft.id,
        tid: tid,
        from: "NULL",
        to: to_addr,
        price: this.nft.data.price,
        payment_id: "",
        type: "COINBASE",
        status: "PENDING",
      };
      axios
        .request(options)
        .then(async function (response) {
          console.log(response);
          // Insert TXN record
          record.payment_id = response.data.data.id;
          const { data, error } = await s.from("txns").insert([record]);
          if (error) {
            console.log(error);
            return;
          } else {
            console.log(response.data.data.hosted_url);
            window.location.href = response.data.data.hosted_url;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
  },
};
</script>
