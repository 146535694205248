import { render } from "./NFT.vue?vue&type=template&id=bc68c65a&scoped=true"
import script from "./NFT.vue?vue&type=script&lang=js"
export * from "./NFT.vue?vue&type=script&lang=js"

import "./NFT.vue?vue&type=style&index=0&id=bc68c65a&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-bc68c65a"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QImg,QIcon,QCard,QCardSection,QAvatar,QItemLabel,QSpinnerHourglass,QTable,QDialog,QCardActions});qInstall(script, 'directives', {ClosePopup});
